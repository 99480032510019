.icon{
    font-size:3vw;
    color:gray;
    padding:1.5vw;
}
.icon:hover{
    color:blue;

}
.contactcontainer{
    display:flex;
    justify-content: center;
    height:5vw;
    width:100vw;
}
@media screen and (max-width: 820px){
    .icon{
        font-size:7vw;
        color:gray;
    }
    .contactcontainer{
        display:flex;
        justify-content: center;
        height:5vw;
        width:100vw;
        padding-bottom:5vh;
    }
    
}