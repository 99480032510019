.navbar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 7vh;
background-color: white;
    /* position:sticky; */
    position:fixed;
    z-index:100;
    top:0;
    
   /* // background-color: #f00000; */
}
.shrinkbutton{
    width: 6vh;
    height: 6vh;
    position: fixed;
    top:0.9vh;
    z-index: 1000;
    border-radius: 50%;
    border:none;
    /* background-color:gray; */
}
.shrinkbutton:hover{
    animation: colors 2s infinite;
}
@keyframes colors{
    0%{
        background-color:blue;
    }
    25%{
        background-color: red;
    }
    50%{
        background-color: yellow;
    }
    75%{
        background-color: green;
    }
}
.shrinkbutton:active{
    outline:none;
    background-color:blue;
}
.feature {
    font-size: 1.3rem;
    opacity: 100;
    flex: 1;
    padding: 0 0;
    }
    .features{
        display:flex;
        justify-content: space-between;
        padding: 0 1.5vw;
        width:100%;
        font-size: 5vh;
    }
    .link1,.link2,.link3,.link4,.link5{
text-decoration: none;
color: black;
    }
   .link1:hover{
       color:blue;
   }
   .link2:hover{
       color:red;
   }
   .link3:hover{
    color:yellow;
   }
   .link4:hover{
    color:green;
   }
#name{
    flex:7;
    font-size:1.5em;
    padding-left:2vw;
}
h1 {
    font-family: 'Computer-Modern', sans-serif;
}
@font-face {
    font-family: 'Computer-Modern';
    src: url('../fonts/computer-modern/brightRoman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@keyframes fade{
 0%{
        opacity:1;
 }
    50%{
        opacity:0.5;
    }
    100%{
        opacity:1;
    }

}

@media screen and (max-width:820px) {
    .feature{
        font-size:1em;
        padding-left: 3vw;
        padding-right:3vw;
    }
}