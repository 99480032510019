h1{
    text-align:center;
}
.profile{
    margin:auto;
}
.aboutdetailscontainer{
     display:flex; 
     flex-direction:column;
    justify-content: center;
    padding:5vh 5vh;
    color:white;
}
.abouttext{
    color:black;
    font-size:2em;
    text-align:center;
    padding-top:5vh; 
    width:60vw;
    margin:auto;
}
.hobbycontainer{
    display:flex;
    justify-content: center;
padding:5px;
    flex-direction: column;
    color:black;
}
.generallink{
    color:blue;
    text-decoration:none;
    
}
.generallink:hover {
    animation: colorChange 4s infinite;
}

@keyframes colorChange {
    0% { color: blue; }
    25% { color: red; }
    50% { color: green; }
    75% { color: yellow; }
    100% { color: blue; }
}

#abouttitle{
margin-top:17vh;
}
#hobbytitle{
    width:100%;
    text-align:center;
    font-size:0.8em;
    font-weight:0.8em;
}
#me{
    border-radius:2vw;
    height:50vh;
}
#tsp{
    border-radius:2vw;
    height:80vh;
}
@media screen and (max-width: 768px) {
    /* .aboutdetailscontainer{
        padding:5vh 0;
    } */
    .abouttext{
        font-size: 1em;
        width:80vw;
    }
    
}